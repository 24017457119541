import { Button, Container } from "../../globalStyles";
import {
  FeaturedTitle,
  Heading,
  Img,
  ImgWrapper,
  InfoColumn,
  InfoRow,
  InfoSec,
  MemberImg,
  MemberProfile,
  Subtitle,
  TeamCol,
  TeamRow,
  TeamSection,
  TextWrapper,
  TopLine,
} from "./InfoSection.elements";

import { Link } from "react-router-dom";
import React from "react";

function InfoSection({
  primary,
  productsColor,
  lightbg,
  lBg,
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  headline1,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  start,
  disp,
  dispBtn,
  featuredTitle,
  Mx_width,
  Pic_Width,
  destination,
}) {
  return (
    <>
      <InfoSec productsColor={productsColor} lightbg={lightbg}>
        <Container>
          <InfoRow imgStart={imgStart}>
            <InfoColumn>
              <TextWrapper data-aos="fade-up" data-aos-duration="400">
                <TopLine lightTopLine={!lightTopLine}>{topLine}</TopLine>
                <Heading lightText={lightText}>
                  {headline} <span>{headline1}</span>
                </Heading>
                <Subtitle Mx_width={Mx_width} lightTextDesc={lightTextDesc}>
                  {description}
                </Subtitle>
                <Link to={destination || "/sign-up"}>
                  <Button dispBtn={dispBtn} fontBig primary={primary}>
                    {buttonLabel}
                  </Button>
                </Link>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper
                start={start}
                data-aos="fade-down"
                data-aos-duration="400"
              >
                <Img WEDTH={Pic_Width} src={img} alt={alt} />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>

          <TeamRow disp={disp}>
            <TeamCol>
              <FeaturedTitle lightText={lightText}>
                {featuredTitle}
              </FeaturedTitle>
              <TeamSection>
                <MemberProfile data-aos="fade-up" data-aos-duration="500">
                  <MemberImg>
                    <img
                      src="images/SAP-Business-All-in-One.jpg"
                      alt="portrait"
                      className="img-circle img-fluid img"
                    />

                    {/* <MemberName>
                      <p>example</p>
                    </MemberName>
                    <MemberTitle>
                      <p>Senior Front-End</p>
                    </MemberTitle> */}
                  </MemberImg>
                </MemberProfile>

                <MemberProfile data-aos="fade-up" data-aos-duration="600">
                  <MemberImg>
                    <img
                      src="images/SAP-Business-Objects.jpg"
                      alt="portrait"
                      className="img-circle img-fluid img"
                    />
                    {/* <MemberName>
                      <p>example</p>
                    </MemberName>
                    <MemberTitle>
                      <p>Senior Front-End</p>
                    </MemberTitle> */}
                  </MemberImg>
                </MemberProfile>

                <MemberProfile data-aos="fade-up" data-aos-duration="700">
                  <MemberImg>
                    <img
                      src="images/SAP-HANA-Enterprise-Cloud.jpg"
                      alt="portrait"
                      className="img-circle img-fluid img"
                    />
                    {/* <MemberName>
                      <p>example</p>
                    </MemberName>
                    <MemberTitle>
                      <p>Senior Front-End</p>
                    </MemberTitle> */}
                  </MemberImg>
                </MemberProfile>
                <MemberProfile data-aos="fade-up" data-aos-duration="800">
                  <MemberImg>
                    <img
                      src="images/SAP-channel-partner.jpg"
                      alt="portrait"
                      className="img-circle img-fluid img"
                    />
                    {/* <MemberName>
                      <p>example</p>
                    </MemberName>
                    <MemberTitle>
                      <p>Senior Front-End</p>
                    </MemberTitle> */}
                  </MemberImg>
                </MemberProfile>
              </TeamSection>
            </TeamCol>
          </TeamRow>
        </Container>
      </InfoSec>
    </>
  );
}

export default InfoSection;
