import "./Form.css";

import {
  ContactHeading,
  ContactInfo,
  Heading,
  SignupColumn,
  SignupRow,
  SignupSec,
  SocialIconLink,
  SocialIcons,
  Subtitle,
  TextWrapper,
  TextWrapper2,
  TopLine,
} from "./PDF.elements";
import { FaFacebook, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";

import { Container } from "../../globalStyles";
import React from "react";

const FormSuccess = (
  lightBg,
  lightTopLine,
  lightText,
  lightTextDesc,
  imgStart
) => {
  return (
    <SignupSec data-aos="fade-up" data-aos-duration="2400" lightBg={lightBg}>
      <Container>
        <SignupRow
          data-aos="fade-up"
          data-aos-duration="2400"
          imgStart={!imgStart}
        >
          <SignupColumn>
            <TextWrapper>
              <TopLine lightTopLine={lightTopLine}>Congratulations</TopLine>
              <Heading lightText={!lightText}>
                A Mail has been sent to you.
              </Heading>
              {/* <Subtitle lightTextDesc={!lightTextDesc}>Reach a wider audience, customize your dedicated landing page, and take advantage of our personalization process and marketing growth initiatives.  </Subtitle> */}

              {/* <Button big fontBig primary={primary}>
                    {buttonLabel}
                  </Button> */}
            </TextWrapper>
          </SignupColumn>
          <SignupColumn>
            <ContactInfo lightText={!lightText}>
              <TextWrapper2>
                <Heading>Contact information</Heading>
                <ContactHeading lightText={!lightText}>
                  Telephone:
                </ContactHeading>
                <Subtitle lightTextDesc={!lightTextDesc}>+20224142502</Subtitle>
                <ContactHeading lightText={!lightText}>Fax:</ContactHeading>
                <Subtitle lightTextDesc={!lightTextDesc}>+20224149952</Subtitle>
                <ContactHeading lightText={!lightText}>Mail:</ContactHeading>
                <Subtitle lightTextDesc={!lightTextDesc}>sales@cic.ae</Subtitle>
                <ContactHeading lightText={!lightText}>
                  Social Media:
                </ContactHeading>
                <Subtitle lightTextDesc={!lightTextDesc}>
                  <SocialIcons>
                    <SocialIconLink
                      href="https://www.facebook.com/CICMENA/"
                      target="_blank"
                      aria-label="Facebook"
                    >
                      <FaFacebook />
                    </SocialIconLink>
                    <SocialIconLink
                      href="https://twitter.com/cicmena"
                      target="_blank"
                      aria-label="Instagram"
                    >
                      <FaTwitter />
                    </SocialIconLink>
                    <SocialIconLink
                      href="https://www.linkedin.com/company/cicmena/mycompany/"
                      target="_blank"
                      aria-label="Linkedin"
                    >
                      <FaLinkedin />
                    </SocialIconLink>
                    <SocialIconLink
                      href={
                        "//www.youtube.com/watch?v=TbEYE8g1k_Y&ab_channel=IBMServices"
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label="Youtube"
                    >
                      <FaYoutube />
                    </SocialIconLink>
                  </SocialIcons>
                </Subtitle>
                <ContactHeading lightText={!lightText}>Address</ContactHeading>
                <Subtitle lightTextDesc={!lightTextDesc}>
                  9 Ibn Batouta street, 3rd floor, Heliopolis, Cairo, Egypt{" "}
                </Subtitle>
              </TextWrapper2>
            </ContactInfo>
          </SignupColumn>
        </SignupRow>
      </Container>
    </SignupSec>
  );
};

export default FormSuccess;
