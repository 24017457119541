import "./Form.css";

import { Button, Container } from "../../globalStyles";
import {
  CommentInput,
  CommentTextArea,
  CommentWrapper,
  ContactHeading,
  ContactInfo,
  Form,
  FormInput,
  FormWrapper,
  Heading,
  SignupColumn,
  SignupRow,
  SignupSec,
  SocialIconLink,
  SocialIcons,
  Subtitle,
  TextWrapper,
  TextWrapper2,
  TopLine,
} from "./PDF.elements";
import { FaFacebook, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import React, { useState } from "react";

import Validate from "./ValidateInfo";
import { sendMail } from "./Mail";
import useForm from "./useform";

// import { Link } from 'react-router-dom';

// import logo from '../../images/Groupi1.svg';
// import logo2 from '../../images/Groupi2.svg';
// import logo3 from '../../images/Groupi3.svg';

const PDF = ({
  lightBg,
  lightTopLine,
  lightText,
  lightTextDesc,
  contactStart,
  submitForm,
}) => {
  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    Validate
  );

  return (
    <>
      <SignupSec data-aos="fade-up" data-aos-duration="2400" lightBg={lightBg}>
        <Container>
          <SignupRow
            data-aos="fade-up"
            data-aos-duration="2400"
            contactStart={contactStart}
          >
            <SignupColumn>
              <TextWrapper>
                <TextWrapper2>
                  <TopLine lightTopLine={!lightTopLine}>
                    How does it work?
                  </TopLine>
                  <Heading lightText={!lightText}>
                    Please fill in the following information
                  </Heading>
                  {/* <Subtitle lightTextDesc={!lightTextDesc}>
                   .{" "}
                  </Subtitle> */}
                </TextWrapper2>

                <Form onSubmit={handleSubmit} noValidate>
                  <FormWrapper>
                    <FormInput
                      borderco={errors.username}
                      name="username"
                      type="text"
                      placeholder="Your Name"
                      pattern=""
                      value={values.username}
                      onChange={handleChange}
                      // required
                    />
                    {errors.username && <p>{errors.username}</p>}
                  </FormWrapper>
                  <FormWrapper>
                    <FormInput
                      borderco={errors.company_name}
                      name="company_name"
                      type="text"
                      placeholder="Company Name"
                      pattern=""
                      value={values.company_name}
                      onChange={handleChange}
                      // required
                    />
                    {errors.company_name && <p>{errors.company_name}</p>}
                  </FormWrapper>

                  <FormWrapper>
                    <FormInput
                      borderco={errors.email}
                      value={values.email}
                      onChange={handleChange}
                      name="email"
                      type="email"
                      placeholder="Add Your Email"
                      pattern="/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/"
                      // required
                    />

                    {errors.email && <p>{errors.email}</p>}
                  </FormWrapper>
                  <FormWrapper>
                    <FormInput
                      borderco={errors.phone}
                      value={values.phone}
                      onChange={handleChange}
                      name="phone"
                      type="phone"
                      placeholder="Phone number"
                      pattern=""
                      // required
                    />
                    {errors.phone && <p>{errors.phone}</p>}
                  </FormWrapper>
                  <FormWrapper>
                    <FormInput
                      borderco={errors.subject}
                      value={values.subject}
                      onChange={handleChange}
                      name="subject"
                      type="text"
                      placeholder="Subject"
                      pattern=""
                      // required
                    />

                    {errors.subject && <p>{errors.subject}</p>}
                  </FormWrapper>

                  <CommentWrapper>
                    {/* <CommentTextArea /> */}
                    <CommentTextArea
                      rows={10}
                      borderco={errors.message}
                      value={values.message}
                      onChange={handleChange}
                      name="message"
                      type="text"
                      placeholder="Enter your message here"
                      pattern=""
                      // required
                    />
                    {errors.message && <p>{errors.message}</p>}
                  </CommentWrapper>

                  <Button
                    big
                    primary
                    onClick={(e) => {
                      handleChange(e);
                    }}
                    Color
                    // borderColor={"#FBB03B"}
                    type="submit"
                  >
                    Send
                  </Button>
                </Form>
              </TextWrapper>
            </SignupColumn>
            <SignupColumn>
              <ContactInfo lightText={!lightText}>
                <TextWrapper2>
                  <Heading>Contact information</Heading>
                  <ContactHeading lightText={!lightText}>
                    Telephone:
                  </ContactHeading>
                  <Subtitle lightTextDesc={!lightTextDesc}>
                    +20224142502
                  </Subtitle>
                  <ContactHeading lightText={!lightText}>Fax:</ContactHeading>
                  <Subtitle lightTextDesc={!lightTextDesc}>
                    +20224149952
                  </Subtitle>
                  <ContactHeading lightText={!lightText}>Mail:</ContactHeading>
                  <Subtitle lightTextDesc={!lightTextDesc}>
                    sales@cic.ae
                  </Subtitle>
                  <ContactHeading lightText={!lightText}>
                    Social Media:
                  </ContactHeading>
                  <Subtitle lightTextDesc={!lightTextDesc}>
                    <SocialIcons>
                      <SocialIconLink
                        href="https://www.facebook.com/CICMENA/"
                        target="_blank"
                        aria-label="Facebook"
                      >
                        <FaFacebook />
                      </SocialIconLink>
                      <SocialIconLink
                        href="https://twitter.com/cicmena"
                        target="_blank"
                        aria-label="Instagram"
                      >
                        <FaTwitter />
                      </SocialIconLink>
                      <SocialIconLink
                        href="https://www.linkedin.com/company/cicmena/mycompany/"
                        target="_blank"
                        aria-label="Linkedin"
                      >
                        <FaLinkedin />
                      </SocialIconLink>
                      <SocialIconLink
                        href={
                          "//www.youtube.com/watch?v=TbEYE8g1k_Y&ab_channel=IBMServices"
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label="Youtube"
                      >
                        <FaYoutube />
                      </SocialIconLink>
                    </SocialIcons>
                  </Subtitle>
                  <ContactHeading lightText={!lightText}>
                    Address
                  </ContactHeading>
                  <Subtitle lightTextDesc={!lightTextDesc}>
                    9 Ibn Batouta street, 3rd floor, Heliopolis, Cairo, Egypt{" "}
                  </Subtitle>
                </TextWrapper2>
              </ContactInfo>
            </SignupColumn>
          </SignupRow>
        </Container>
      </SignupSec>
    </>
  );
};
// onClick={(e) => handleSubmit(e), sendMail(e, info.mail, info.phone, info.name, info.Company_name)}
export default PDF;
