import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import {
  FooterContainer,
  FooterLink,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterSubHeading,
  FooterSubText,
  FooterSubscription,
  Form,
  FormInput,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
} from "./Footer.elements";

import { Button } from "../../globalStyles";
import React from "react";
import logo from "../../images/cic-logo-final-14.svg";

function Footer() {
  return (
    <FooterContainer>
      <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>About Us</FooterLinkTitle>
            <FooterLink to="/sign-up">How it works</FooterLink>
            <FooterLink to="/partners">Partnership</FooterLink>
            <FooterLink to="/products">Products</FooterLink>
            <FooterLink to="/services">services</FooterLink>
            <FooterLink to="/about">About </FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Contact Us</FooterLinkTitle>
            <FooterLink to="/sign-up">Contact</FooterLink>
            <FooterLink to="/sign-up">Support</FooterLink>
            {/* <FooterLink to="/">Destinations</FooterLink>
            <FooterLink to="/">Sponsorships</FooterLink> */}
          </FooterLinkItems>
        </FooterLinksWrapper>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Social Media</FooterLinkTitle>
            <FooterLink to="/sign-up">Linkedin</FooterLink>
            <FooterLink to="/sign-up">Facebook</FooterLink>
            <FooterLink to="/sign-up">Youtube</FooterLink>
            <FooterLink to="/sign-up">Twitter</FooterLink>
          </FooterLinkItems>
        </FooterLinksWrapper>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Locations</FooterLinkTitle>
            <FooterLink>Egypt: 9 Ebn Battota St. 3rd Floor, El Baroon Palace, Cairo</FooterLink>
            <FooterLink>KSA: Aqaria Plaza, Olaya Street, Riyadh</FooterLink>
            <FooterLink>UAE: Al Hamid Buildings 501, Al Muror Road, Abu Dhabi</FooterLink>
          </FooterLinkItems>
        </FooterLinksWrapper>
        {/* <FooterSubscription>
          <FooterSubHeading>
            Subscribe to receive our latest news
          </FooterSubHeading>
          <FooterSubText>You can unsubscribe at any time.</FooterSubText>
          <Form>
            <FormInput name="email" type="email" placeholder="Your Email" />
            <Button fontBig>Subscribe</Button>
          </Form>
        </FooterSubscription> */}
      </FooterLinksContainer>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to="/">
            {/* <SocialIcon /> */}
            <img src={logo} alt="cic logo" />
          </SocialLogo>
          <WebsiteRights>CIC © 2021</WebsiteRights>
          <SocialIcons>
            <SocialIconLink
              href="https://www.facebook.com/CICMENA/"
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink
              href="https://twitter.com/cicmena"
              target="_blank"
              aria-label="Instagram"
            >
              <FaTwitter />
            </SocialIconLink>
            <SocialIconLink
              href="https://www.linkedin.com/company/cicmena/mycompany/"
              target="_blank"
              aria-label="Linkedin"
            >
              <FaLinkedin />
            </SocialIconLink>
            <SocialIconLink
              href={
                "//www.youtube.com/watch?v=TbEYE8g1k_Y&ab_channel=IBMServices"
              }
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Youtube"
            >
              <FaYoutube />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;
