import styled, { createGlobalStyle, keyframes } from "styled-components";

import Gilroy1 from "./fonts/Gilroy-Black.ttf";
import Gilroy2 from "./fonts/Gilroy-Medium.ttf";

const GlobalStyle = createGlobalStyle`

/* body {
  font-family: 'Raleway', sans-serif;
  background-image: radial-gradient(circle at center, #899Dc4, #495D84);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(ellipse at bottom, #1b2735, #090a0f);
  font-weight: 300;
} */

  body {
    line-height: unset;
    letter-spacing: normal
  }
  
  dl, ol, ul {
    margin-bottom: 0;
  }
  .mt-5{
    margin-top: 100px !important;
  }
  .bg-secondary{
    background: #EDF4FD !important  ;
  }

  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Gilroy', sans-serif;
  @font-face {
        font-family: 'Gilroy';
        src: local("/public/fonts/Gilroy-Black.ttf"), local("/public/fonts/Gilroy-Black.ttf"),
        url(${Gilroy1}) format('ttf'),
        url(${Gilroy2}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
  
 } 
 
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;

  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const slideUp = keyframes`
    0% {
        opacity: 0;
        transform: translatey(400%);
    } 30% {
        opacity: 0.001;
    } 100% {
        opacity: 1;
        transform: translatey(0);
    }
`;

export const Button = styled.button`
  display: ${({ dispBtn }) => (dispBtn ? "none" : "inline-block")};
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  background: #5a7ab9;
  /* background: ${({ btnColor, primary }) =>
    btnColor ? btnColor : primary ? "#1000FF" : "#0467FB"}; */
  border: ${({ borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : "none"};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  width: ${({ full }) => (full ? "100%" : "auto")};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  //border: none;
  cursor: pointer;
  transition: ${({ hide }) => (hide ? "all 0.4s ease" : "all 0.3s ease-out")};
  opacity: ${({ hide }) => (hide ? "0" : "1")};

  animation: ${({ animated }) => (animated ? `slideUp 1.2s ease-in` : "none")};

  @keyframes slideUp {
    0% {
      opacity: 0;
      transform: translatey(400%);
      overflow: hidden;
    }
    30% {
      opacity: 0;
      overflow: hidden;
    }
    100% {
      opacity: 1;
      transform: translatey(0);
      overflow: hidden;
    }
  }

  &:before {
    content: "";
    height: 150px;
    width: 40px;
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    transform: translateX(-100%) rotate(20deg);
    top: -20px;
    left: -100%;
    transition: all 0.5s ease;
    // overflow: hidden;
  }
  &:hover {
    // overflow: hidden;
    transition: all 0.7s ease-out;
    background: #fff;
    background: ${({ btnColor, primary, borderColor }) =>
      borderColor ? borderColor : primary ? "#0467FB" : "#4B59F7"};
  }
  &:hover:before {
    // overflow: hidden;
    transform: translateX(100%) rotate(20deg);
    left: 100%;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export default GlobalStyle;
